@use '@/styles/mixins';

.cutout {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-modal);
  width: 100%;
  height: 100%;
  pointer-events: none;
  -webkit-app-region: no-drag;

  .border {
    position: absolute;

    @include mixins.fade-in;

    pointer-events: auto;
    -webkit-app-region: drag;

    &.noDrag {
      -webkit-app-region: no-drag;
    }

    &.blur {
      background-color: color-mix(
        in srgb,
        var(--background-primary) 76%,
        transparent
      );
    }
  }
}
