@mixin scrollable($thickness: 6px) {
  overflow: overlay;

  &::-webkit-scrollbar {
    width: $thickness;
    height: $thickness;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: $thickness;
    height: $thickness;
    background-color: transparent;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: var(--outline-primary);
    }
  }
}

@mixin hidden-scrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
}

@mixin fade-in() {
  opacity: 0;
  animation: fade-in 0.24s forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
