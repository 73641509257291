@import 'context-bridge-api/dist/variables.css';

html,
body {
  width: 100%;
  min-height: 100%;
}

html {
  font-family: var(--primary-font);
}

body {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

// Uncomment to hide error modal in dev
// nextjs-portal {
//   display: none;
// }
