:root {
  /* Sizes */
  --t0-slim: 500 18px/24px var(--primary-font);
  --t0: 600 18px/24px var(--primary-font);
  --t0-bold: 700 18px/24px var(--primary-font);
  --t1-slim: 500 15px/24px var(--primary-font);
  --t1-bold: 700 15px/24px var(--primary-font);
  --t2-slim: 500 13px/18px var(--primary-font);
  --t2-bold: 600 13px/18px var(--primary-font);
  --t3-slim: 500 10px/12px var(--primary-font);
  --t3-bold: 600 10px/12px var(--primary-font);
  --h1-slim: 500 24px/36px var(--primary-font);
  --t0-slim-secondary: 500 18px/24px var(--secondary-font);
  --t0-bold-secondary: 700 18px/24px var(--secondary-font);
  --t1-slim-secondary: 500 15px/24px var(--secondary-font);
  --t1-secondary: 600 15px/24px var(--secondary-font);
  --t1-bold-secondary: 700 15px/24px var(--secondary-font);
  --t2-slim-secondary: 500 13px/18px var(--secondary-font);
  --t2-bold-secondary: 600 13px/18px var(--secondary-font);
  --t3-slim-secondary: 500 10px/12px var(--secondary-font);
  --t3-bold-secondary: 600 10px/12px var(--secondary-font);
  --h1-slim-secondary: 500 24px/36px var(--secondary-font);

  /* Shadows */
  --shadow-none: 0 0 0 transparent;
  --shadow-s1: 0px 1px 3px rgb(0 0 0 / 24%);
  --shadow-s1-bold: 0px 3px 12px rgb(0 0 0 / 24%);
  --shadow-s2: 0px 3px 6px rgb(0 0 0 / 24%);
  --shadow-s3: 0px 3px 6px rgb(0 0 0 / 36%);
  --shadow-s4: 0px 2px 18px rgb(0 0 0 / 24%);
  --shadow-s5: 0px 2px 36px rgb(0 0 0 / 60%);

  /* Colors */
  --grey-0-white: rgba(255, 255, 255, 1);
  --grey-03-bluish: rgba(245, 248, 251, 1);
  --grey-10: rgba(230, 230, 230, 1);
  --grey-24: rgba(194, 194, 194, 1);
  --grey-42: rgba(148, 148, 148, 1);
  --grey-60: rgba(102, 102, 102, 1);
  --grey-72: rgba(71, 71, 71, 1);
  --grey-80: rgba(51, 51, 51, 1);
  --grey-90: rgba(26, 26, 26, 1);
  --greay-100--dark: rgba(0, 0, 0, 1);
  --grey-bluish-surface: rgba(8, 10, 22, 1);
  --blue-light-bg: rgba(228, 238, 252, 1);
  --blue-light-loading-bg: rgba(184, 220, 249, 1);
  --blue-stroke: rgba(143, 187, 248, 1);
  --blue-btns: rgba(70, 177, 255, 1);
  --blue-text: rgba(22, 147, 238, 1);
  --blue-bg-nice: rgba(38, 132, 255, 1);
  --blue-bg-2nd-clustter: rgba(22, 147, 238, 1);
  --light-red: rgba(252, 228, 228, 1);
  --pink: rgba(217, 58, 231, 1);
  --pink-the-gist: rgba(255, 37, 142, 1);
  --red-text: rgba(252, 27, 67, 1);
  --green: rgba(90, 185, 76, 1);
  --yellow: rgba(252, 229, 25, 1);
  --blue-light-bg-hover: rgba(209, 226, 250, 1);
  --very-very-light-blue: rgba(248, 249, 251, 1);
  --dark-4: rgba(50, 50, 72, 1);
  --dark-3: rgba(38, 38, 54, 1);
  --dark-2: rgba(25, 25, 36, 1);
  --dark-1: rgba(12, 12, 18, 1);
  --dark--select: rgba(78, 78, 166, 1);
  --dark--select-2: rgba(35, 52, 118, 1);
  --dark--select-3: rgba(45, 45, 100, 1);

  /* Gradients */
  --gradient-1: linear-gradient(202.33deg, #f63bfa 0%, #51f5cd 103.09%);
  --gradient-2: linear-gradient(180deg, #db89f7 0%, #2a86f3 100%);
  --background-gradient-1: linear-gradient(
    180deg,
    #fff 0%,
    #f3f6fa 18.33%,
    #f5f9ff 72.51%,
    #f2f6fb 90.63%,
    #f2f5fb 100%
  );
  --background-gradient-2: linear-gradient(270deg, #ecf0f7 0%, #fff 100%);
  --radius: 12px;

  /* Effect styles */
  --s1---light-shadow: 0px 1px 3px rgba(0, 0, 0, 0.24);
  --s2----thicker-shadow: 0px 3px 6px rgba(0, 0, 0, 0.24);
  --s3--: 0px 3px 6px rgba(0, 0, 0, 0.36);
  --s4----spread-out-light: 0px 2px 18px rgba(0, 0, 0, 0.24);
  --s5---spread-out-hard: 0px 2px 36px rgba(0, 0, 0, 0.6);
  --s6--complex-shadow: 0px 2.767256498336792px 2.2138051986694336px
      rgba(0, 0, 0, 0.02),
    0px 6.650102138519287px 5.32008171081543px rgba(0, 0, 0, 0.03),
    0px 12.521552085876465px 10.017241477966309px rgba(0, 0, 0, 0.04),
    0px 22.3363094329834px 17.869047164916992px rgba(0, 0, 0, 0.04),
    0px 41.777610778808594px 33.422088623046875px rgba(0, 0, 0, 0.05),
    0px 100px 80px rgba(0, 0, 0, 0.07);
  --b1----blur-60: ;

  /* Z Indices */
  --z-index-modal: 1000;
  --z-index-dropdown: 900;
  --z-index-tooltip: 800;
  --z-index-forefront: 1;
  --z-index-backdrop: -1;
}

[data-theme='dark'] {
  --text-primary: var(--grey-0-white);
  --text-secondary: var(--grey-24);
  --text-tertiary: var(--grey-60);
  --text-inverted: var(--dark-1);

  --background-primary: var(--dark-1);
  --background-secondary: var(--dark-2);
  --background-tertiary: var(--dark-3);
  --background-inverted: var(--grey-0-white);
  --background-highlight: var(--blue-light-bg);

  --outline-primary: var(--dark-4);
  --primary-color: var(--blue-text);
  --secondary-color: var(--pink-the-gist);
}

[data-theme='light'] {
  --text-primary: var(--dark-1);
  --text-secondary: var(--dark-2);
  --text-tertiary: var(--dark-3);
  --text-inverted: var(--grey-0-white);

  --background-primary: var(--grey-60);
  --background-secondary: var(--grey-24);
  --background-tertiary: var(--grey-0-white);
  --outline-primary: var(--dark-4);
  --background-inverted: var(--grey-0-white);
  --background-highlight: var(--blue-light-bg);

  --primary-color: var(--blue-text);
  --secondary-color: var(--pink-the-gist);
}
